import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/web';
import { InnovationLayout } from '../../components/entities/innovation-layout';
/* @ts-ignore */
export default createGatsbyPageComponent('Innovation', { layout: InnovationLayout });

export const query = graphql`
  query InnovationQuery($id: ID!) {
    platform {
      innovation(id: $id) {
        __typename _schema { label pluralLabel }
        openGraph { description { text(truncate: { words: 20 }) } image { url } }
        id slug name
        topic { 
          id slug name path
          trend {
            id slug name path
          } 
        }
        description { id text }
        example { id text }
        technologies {
          id name slug _schema { label pluralLabel }
          openGraph { image { id url } }
          organisations { 
            id name slug url
            type { id name slug }
            technologies { id name slug }
            logo { url static { childImageSharp { gatsbyImageData } } }
            openGraph { id image { id url } } 
          }
        }
        catalysts {
          id name path slug _schema { label pluralLabel }
          openGraph { image { url } }
          description { text }
        }
        impactResultTypes {
          id name slug _schema { label pluralLabel }
          openGraph { image { url } }
        }
        commodityGroups {
          id name slug _schema { label pluralLabel }
          openGraph { image { url } }
        }
        barriers {
          id name slug _schema { label pluralLabel }
          openGraph { image { url } }
        }
        horizons {
          name path slug _schema { label pluralLabel }
          type { id name value }
          description { text }
        }
        sdgs {
          id name slug _schema { label pluralLabel }
          openGraph { image { url } }
        }
      }
    }
  }
`;