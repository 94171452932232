import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ActiveEntityProvider, SiteHeaderLayout, SiteHeaderProvider, classNames, useEntity, EntityGridDetailsLayout } from '@shapeable/web';
import { Innovation } from '@shapeable/collaborate-ore-types';
import { InnovationAsideLayout } from './innovation-aside-layout';
import { InnovationMainLayout } from './innovation-main-layout';
import { ExplorerProvider } from '../providers/explorer-provider';
import { useValueChainMap } from '../../hooks/use-value-chain-map';
const cls = classNames('innovation-details-layout');


// -------- Types -------->

export type InnovationLayoutProps = Classable & HasChildren & { 
  entity?: Innovation;
};

export const InnovationLayoutDefaultProps: Omit<InnovationLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

  `,
});

const SiteHeaderStyles = breakpoints({
  base: css`
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    flex-grow: 1;
    padding: ${theme.UNIT(5)} 0;
  `,
});

const AsideStyles = breakpoints({
  base: css`
    
  `,
});

const MainStyles = breakpoints({
  base: css`
    
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${SiteHeaderStyles}`,
    Layout: styled(EntityGridDetailsLayout).attrs(cls.attr('Layout'))`${LayoutStyles}`,
      Aside: styled(InnovationAsideLayout)`${AsideStyles}`,
      Main: styled(InnovationMainLayout)`${MainStyles}`
};

export const InnovationLayout: Shapeable.FC<InnovationLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const { isShown } = useValueChainMap();

  return (
    <ExplorerProvider>
    <My.Container className={cls.name(className)}>
      <My.Header variant="light" />
      <My.Layout
        entity={entity}
        asideIsRevealed={isShown}
        aside={
          <My.Aside entity={entity} />
        }
        main={
          <My.Main entity={entity} />
        }
      />
      {children}
    </My.Container>
    </ExplorerProvider>
  )
};

InnovationLayout.defaultProps = InnovationLayoutDefaultProps;
InnovationLayout.cls = cls;