import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, Person } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Grid, OrganisationBioCell, PersonBioCell, classNames, entityTypeNameFor, useEntity, useForm, useFullscreen } from '@shapeable/web';
const cls = classNames('stakeholder-bio-cell-grid');

// -------- Types -------->

export type CommunityBioCellGridProps = Classable & HasChildren & { 
  items?: Entity[];
};

export const CommunityBioCellGridDefaultProps: CommunityBioCellGridProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    padding: 20px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;
    flex-wrap: wrap;
  `,
});

const GridStyles = breakpoints({
  base: css`
  
  `,
});

const CellContainerStyles = breakpoints({
  base: css`
    padding: 20px;
  `,
});

const StakeholderCellStyles = breakpoints({
  base: css`
    background: var(--shapeable-brightest, #FFF);
    max-width: 200;
    min-width: 180px;
    min-height: 300px;
    box-sizing: border-box;
    padding: 48px 12px 20px 12px;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justfiy-content: flex-start;
    flex: 1 1 0;
  `,
});





// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Grid: styled(Grid)`${GridStyles}`,
    CellContainer: styled.div`${CellContainerStyles}`,
      Organisation: styled(OrganisationBioCell)`${StakeholderCellStyles}`,
      Person: styled(PersonBioCell)`${StakeholderCellStyles}`,
};

export const CommunityBioCellGrid: Shapeable.FC<CommunityBioCellGridProps> = (props) => {
  const { className, children, items, ...rest } = props;

  const fs = useFullscreen();


  return (
    <My.Container className={cls.name(className)}>
      {
        items.map(stakeholder => {
          const type = entityTypeNameFor(stakeholder);
          
          return type === 'Organisation' ? 
            <My.Organisation key={stakeholder.id} entity={stakeholder} /> :
            <My.Person key={stakeholder.id} entity={stakeholder as Person} />
        })
      }
    {children}
    </My.Container>
  )
};

CommunityBioCellGrid.defaultProps = CommunityBioCellGridDefaultProps;
CommunityBioCellGrid.cls = cls;