import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { Horizon, HorizonType } from '@shapeable/collaborate-ore-types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity } from '@shapeable/web';
import { HorizonCard } from './horizon-card';
const cls = classNames('horizon-card-grid');
import { compact } from 'lodash';
import { useHorizonTypes } from '../../hooks/use-horizon-types';

// -------- Types -------->

export type HorizonCardGridProps = Classable & HasChildren & { 
  items: Horizon[];
};

export const HorizonCardGridDefaultProps: HorizonCardGridProps = {
  items: []
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  `,
});

const ItemStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Horizon: styled(HorizonCard)`${ItemStyles}`,
};

export const HorizonCardGrid: Shapeable.FC<HorizonCardGridProps> = (props) => {
  const { className, children, items } = props;

  const horizonTypes = useHorizonTypes();

  const types = compact(items.map((item) => item?.type));
  const horizonValue = types.map((type: HorizonType) => type?.value );

  return (
    <My.Container className={cls.name(className)}>
    {
      horizonTypes.map((type: HorizonType )=> {
        const isHorizon = horizonValue.includes(type.value);
        const itemWithDescription = items.find((item) => item.type?.id === type.id);
        const description = itemWithDescription?.description || {text: ''};

        return (
          <My.Horizon key={type.id} entity={type} description={description} hasTick={isHorizon}/>
        )
      })
    }
    {children}
    </My.Container>
  )
};
HorizonCardGrid.defaultProps = HorizonCardGridDefaultProps;
HorizonCardGrid.cls = cls;